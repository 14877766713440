@import "styles/abstracts/index";
.row {
  .key {
    color: var(--black-medium);
  }
  .value {
    color: var(--green-bright);
    margin-left: 8px;
  }
  .value,
  .key {
    font-size: 16px;
    font-family: var(--font-secondary);

    @include md-down {
      font-size: 12px;
    }
  }
}
.listItem {
  display: flex;
  gap: 12px;
  margin-top: 16px;

  @include md-down {
    margin-top: 0.75rem;
  }
}

.toolTipWrap:first-child .listItem{
  margin-top: 0;
}

.toolTipWrap {
  position: relative;
  list-style: none;

  .toolTip {
    position: absolute;
    background-color: var(--black);
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0;
    z-index: 1;
    left: 24px;
    width: 400px;
    font-size: 14px;
    color: var(--white);
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
    margin: 0;
    font-family: var(--font-primary);
    @include md-down {
      width: 250px;
      font-size: 12px;
    }
  }

  &:hover {
    .toolTip {
      transform: scale(1);
      opacity: 1;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
}

.image svg {
  height: 16px;
  width: 16px;

  display: inline;
  line-height: 1em;
  flex-shrink: 0;
  vertical-align: middle;

  @include md-down {
    height: 12px;
    width: 12px;
  }
}
